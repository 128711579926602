@use "../_mixins/break-points" as *;

@include media-breakpoint-up(xxl) {
	/*====================================================================/
/*--Large PC 以上--
/====================================================================*/
}
@include media-breakpoint-up(xl) {
	/*====================================================================/
/*---PC 以上--
/====================================================================*/
}
@include header-up {
	/*====================================================================/
	/*--header breakpoint 以上--
	/====================================================================*/
}
@include media-breakpoint-up(lg) {
	/*====================================================================/
/*--XGAサイズ　iPadPro 以上--
/====================================================================*/
}
@include media-breakpoint-up(md) {
	/*====================================================================/
/*--タブレット　iPadmini 以上--
/====================================================================*/
}
@include media-breakpoint-up(sm) {
	/*====================================================================/
/*--スマホ 以上--
/====================================================================*/
}
@include media-breakpoint-up(xs) {
	/*====================================================================/
/*--iPhone 以上--
/====================================================================*/
}

@include media-breakpoint-down(xl) {
	/*====================================================================/
/*--Large PC 未満--
/====================================================================*/
}
@include header-down {
	/*====================================================================/
/*--header breakpoint 未満--
/====================================================================*/
}
@include media-breakpoint-down(lg) {
	/*====================================================================/
/*--PC 未満--
/====================================================================*/
}
@include media-breakpoint-down(md) {
	/*====================================================================/
/*--XGAサイズ iPadPro 未満--
/====================================================================*/
}
@include media-breakpoint-down(sm) {
	/*====================================================================/
/*--タブレット iPadmini 未満--
/====================================================================*/
}
@include media-breakpoint-down(xs) {
	/*====================================================================/
/*--iPhone 以下--
/====================================================================*/
}

@include media-breakpoint-only(sm) {
	/*====================================================================/
/*--スマホ以上 タブレット iPadmini未満--
/====================================================================*/
}
@include media-breakpoint-only(md) {
	/*====================================================================/
/*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
/====================================================================*/
}
@include media-breakpoint-only(lg) {
	/*====================================================================/
/*--XGAサイズ iPadPro以上 PC未満--
/====================================================================*/
}
@include media-breakpoint-only(xl) {
	/*====================================================================/
/*--px以上 Large PC未満--
/====================================================================*/
}
