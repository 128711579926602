@use "../_variables/variables" as *;
@use "break-points" as *;
//===================================================
//	font setting
//===================================================
//font-size
$f-sp: 14; //スマホ用フォントサイズ
$f-tb: 15; //タブレット用フォントサイズ
$f-pc: 16; //PC用フォントサイズ

//font-family
$font-set: (
  font01: "'Noto Sans JP'",
  //メインフォントファミリー
  font02: "'Roboto'",
  //英字フォントファミリー
  font03: "'Roboto Condensed'",
  //その他ファミリー
);

/* webfont 変数　*/
$icons: (
  top_arrow: "\e316",
  right_arrow: "\e315",
  down_arrow: "\e313",
  left_arrow: "\e314",
  link: "\e900",
  tag: "\e901",
  check: "\e902",
  search: "\e905",
  search-plus: "\e908",
  pin: "\e90e",
  facebook1: "\e906",
  facebook2: "\e912",
  twitter: "\e90c",
  instagram: "\e90a",
  Line: "\e90d",
  youtube: "\e910",
  home: "\e90b",
  phone: "\e909",
  mail: "\e911",
  triangle: "\e903",
  pdf: "\e904"
) !default;

@mixin icon($icon: false, $position: before) {
  @if $position == both {
    $position: ":before,:after";
  }
  &:#{$position} {
    @if $icon {
      content: "#{map-get($icons, $icon)}";
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  @content;
}

//default font-family
@mixin set_font-family {
	font-family: #{map-get($font-set,font01)},-apple-system, BlinkMacSystemFont,'Hiragino Sans',"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN",  "游ゴシック","Yu Gothic","游ゴシック体",YuGothicM,YuGothic,"メイリオ", Meiryo, sans-serif;
}

//font-family
@mixin f-family($fontname,$next:sans-serif){
	$name : map-get($font-set, $fontname);
	font-family:#{$name},$next;
}

//font-size
@mixin f-size($size: 14) {
	font-size: round-decimal(calc($size / 16),2) + rem;  
}

//font-weight
@mixin f-w($weight:400){
	font-weight: $weight;
}

//letter-spacing
@mixin l-sp($l-sp:0.1em) {
	letter-spacing: $l-sp;
}

//line-height
@mixin line-h($height:1.75) {
	line-height: $height;
}

//スマホ・タブレット・PCでのデフォルトフォントサイズ
@mixin f-base {
	font-size: floor-decimal(calc($f-sp / 16),4) * 100%;
	@include media-breakpoint-up(sm) {
		font-size:floor-decimal(calc($f-tb / 16),4) * 100%;
	}
	@include media-breakpoint-up(lg) {
		font-size: floor-decimal(calc($f-pc / 16),4) * 100%;
	}
}


@mixin f-em($em:16) {
	font-size:floor-decimal(calc($em / $f-sp),2) * 1em;
	@include media-breakpoint-up(sm) {
		font-size:floor-decimal(calc($em / $f-tb),2) * 1em;
	}
	@include media-breakpoint-up(lg) {
		font-size:floor-decimal(calc($em / $f-pc),2) * 1em;
	}
}


// font-size用
$fontbreak: (
	xxs: 280, //デバイス幅の最小値
	xs: map-get($grid-num, xs),
	sm: map-get($grid-num, sm),
	md: map-get($grid-num, md),
	lg: map-get($grid-num, lg),
	xl: map-get($grid-num, xl)
) !default;
@function fs_clamp($name, $minsize, $maxsize) {
	$min_width: map-get($fontbreak, $name);
	$val_min: $minsize * 1px;
	$val_vw: round-decimal(calc($minsize / $min_width) * 100) * 1vw;
	$val_max: $maxsize * 1px;
	@return clamp(#{$val_min}, #{$val_vw}, #{$val_max});
}


@mixin fs_xxs($minsize, $min_width) {
	font-size: fs_clamp(xxs, $minsize, $min_width);
}
@mixin fs_xs($minsize, $min_width) {
	font-size: fs_clamp(xs, $minsize, $min_width);
}
@mixin fs_sm($minsize, $min_width) {
	font-size: fs_clamp(sm, $minsize, $min_width);
}
@mixin fs_md($minsize, $min_width) {
	font-size: fs_clamp(md, $minsize, $min_width);
}
@mixin fs_lg($minsize, $min_width) {
	font-size: fs_clamp(lg, $minsize, $min_width);
}
@mixin fs_xl($minsize, $min_width) {
	font-size: fs_clamp(xl, $minsize, $min_width);
}


@mixin f-s_xxs($size,$wid) {
	font-size:calc(#{$size}rem + #{$wid} * (100vw - 280px) / #{map-get($grid-num,xl) - 280});
}
@mixin f-s_xs($size,$wid) {
	font-size:calc(#{$size}rem + #{$wid} * (100vw -  #{map-get($grid-num,xs)}px) / #{map-get($grid-num,xl) - map-get($grid-num,xs)});
}
@mixin f-s_sm($size,$wid) {
	font-size:calc(#{$size}rem + #{$wid} * (100vw - #{map-get($grid-num,sm)}px) / #{map-get($grid-num,xl) - map-get($grid-num,sm)});
}
@mixin f-s_md($size,$wid) {
	font-size:calc(#{$size}rem + #{$wid} * (100vw - #{map-get($grid-num,md)}px) / #{map-get($grid-num,xl) - map-get($grid-num,md)});
}
@mixin f-s_lg($size,$wid) {
	font-size:calc(#{$size}rem + #{$wid} * (100vw - #{map-get($grid-num,lg)}px) / #{map-get($grid-num,xl) - map-get($grid-num,lg)});
}
@mixin f-s_xl($size,$wid) {
	font-size:calc(#{$size}rem + #{$wid} * (100vw - #{map-get($grid-num,xl)}px) / #{map-get($grid-num,xxl) - map-get($grid-num,xl)});
}